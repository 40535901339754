<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers"
                    fixed-header
                    :height="windowHeight"
                    :items="data"
                    :search="search"
                    :loading="loading"
                    item-key="_id"
                    dense
                    hide-default-footer
                    disable-pagination
      >
        <template v-slot:item.expToNext="row">{{ row.item.expToNext.toLocaleString() }}</template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AirshipRanks',
  data() {
    return {
      loading: true,
      search: '',
      windowHeight: window.innerHeight - 250,
      data: [],
      headers: [
        {
          text: 'Rank',
          value: 'rank',
          align: 'center',
        },
        {
          text: 'Capacity',
          value: 'capacity',
          align: 'center',
        },
        {
          text: 'Exp to next',
          value: 'expToNext',
          align: 'center',
        },
      ],
    };
  },
  mounted() {
    this.$http.get('/airship/ranks').then((res) => {
      this.data = res.data?.results;
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>

</style>
