<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-expansion-panels :value="0">
          <v-expansion-panel :disabled="false">
            <v-expansion-panel-header>Trip planner</v-expansion-panel-header>
            <v-expansion-panel-content>
                <AirshipCalculatorSectors></AirshipCalculatorSectors>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col cols="auto">
        <v-switch v-model="showLeftSide">
          <template v-slot:label>
            Show filters
          </template>
        </v-switch>
      </v-col>
      <v-col cols="auto">
          <v-switch
            v-model="isVerticalMode"
          >
            <template v-slot:label>
              Vertical mode
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-on="on"
                  v-bind="attrs"
                >mdi-information-outline</v-icon>
                </template>
                <span>
                  This option will make the filters column into a row of its own.<br/>
                  If the width of your browser is not large enough, the display mode will default to
                  vertical mode even if this option is disabled.
                </span>
              </v-tooltip>
            </template>
        </v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-show="showLeftSide" :offset-md="offsetLeft"
        :xs="leftWidthXs"
        :sm="leftWidthSm"
        :md="leftWidthMd"
        :lg="leftWidthLg"
        >
        <v-card>
          <v-card-text>
            <AirshipCalculatorStatsFilters></AirshipCalculatorStatsFilters>
            <AirshipCalculatorPartsSelector
              v-model="selectedParts"
            ></AirshipCalculatorPartsSelector>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        :xs="rightWidthXs"
        :sm="rightWidthSm"
        :md="rightWidthMd"
        :lg="rightWidthLg"
      >
        <v-row dense no-gutters>
          <v-col>
            <v-card>
              <v-card-text>
                <AirshipCalculatorCurrentRoute></AirshipCalculatorCurrentRoute>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row >
          <v-col>
            <AirshipCalculatorCurrentDetails></AirshipCalculatorCurrentDetails>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <AirshipCalculatorBuildsList></AirshipCalculatorBuildsList>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AirshipCalculatorPartsSelector from '@/modules/airships/calculator/AirshipCalculatorPartsSelector.vue';
import AirshipCalculatorSectors from '@/modules/airships/calculator/AirshipCalculatorSectors.vue';
import AirshipCalculatorStatsFilters from '@/modules/airships/calculator/AirshipCalculatorStatsFilters.vue';
import AirshipCalculatorBuildsList from '@/modules/airships/calculator/AirshipCalculatorBuildsList.vue';
import AirshipCalculatorCurrentRoute from '@/modules/airships/calculator/AirshipCalculatorCurrentRoute.vue';
import AirshipCalculatorCurrentDetails from '@/modules/airships/calculator/AirshipCalculatorDetails.vue';

export default {
  name: 'AirshipCalculator',
  components: {
    AirshipCalculatorCurrentDetails,
    AirshipCalculatorCurrentRoute,
    AirshipCalculatorBuildsList,
    AirshipCalculatorStatsFilters,
    AirshipCalculatorPartsSelector,
    AirshipCalculatorSectors,
  },
  data() {
    return {
      showLeftSide: true,
      isVerticalMode: false,

      offsetLeft: '0',
      leftWidthXs: '12',
      leftWidthSm: '12',
      leftWidthMd: '12',
      leftWidthLg: '4',
      leftWidthXl: '4',
      rightWidthXs: '12',
      rightWidthSm: '12',
      rightWidthMd: '12',
      rightWidthLg: '8',
      rightWidthXl: '8',
    };
  },
  mounted() {
    this.sortingPriority = [{ field: 'stats.speed', order: 'desc' }];

    this.$store.commit(
      'airshipsCalculator/SET_SORTING_SORTBY',
      ['stats.speed'],
    );
    this.$store.commit(
      'airshipsCalculator/SET_SORTING_SORTDESC',
      [true],
    );
  },
  computed: {
    sortingPriority: {
      get() {
        return this.$store.state.airshipsCalculator.sortingPriority;
      },
      set(value) {
        this.$store.commit('airshipsCalculator/SET_SORTING_PRIORITY', value);
      },
    },
    selectedParts: {
      get() {
        return this.$store.state.airshipsCalculator.selectedParts;
      },
      set(value) {
        this.$store.commit('airshipsCalculator/SET_SELECTED_PARTS', value);
      },
    },
  },
  watch: {
    isVerticalMode() {
      if (this.isVerticalMode) {
        this.leftWidth = '6';
        this.offsetLeft = '3';
        this.leftWidthXs = '12';
        this.leftWidthSm = '12';
        this.leftWidthMd = '12';
        this.leftWidthLg = '6';
        this.leftWidthXl = '6';
        this.rightWidthXs = '12';
        this.rightWidthSm = '12';
        this.rightWidthMd = '12';
        this.rightWidthLg = '12';
        this.rightWidthXl = '12';
      } else {
        this.offsetLeft = '0';
        this.leftWidthXs = '12';
        this.leftWidthSm = '12';
        this.leftWidthMd = '12';
        this.leftWidthLg = '4';
        this.leftWidthXl = '4';
        this.rightWidthXs = '12';
        this.rightWidthSm = '12';
        this.rightWidthMd = this.showLeftSide ? 12 : 12;
        this.rightWidthLg = this.showLeftSide ? 8 : 12;
        this.rightWidthXl = this.showLeftSide ? 8 : 12;
      }
    },
    showLeftSide() {
      if (this.showLeftSide) {
        this.rightWidthXs = '12';
        this.rightWidthSm = '12';
        this.rightWidthMd = this.isVerticalMode ? 12 : 12;
        this.rightWidthLg = this.isVerticalMode ? 12 : '8';
        this.rightWidthXl = this.isVerticalMode ? 12 : '8';
      } else {
        this.rightWidthXs = '12';
        this.rightWidthSm = '12';
        this.rightWidthMd = '12';
        this.rightWidthLg = '12';
        this.rightWidthXl = '12';
      }
    },
  },
  methods: {
    toggleShowLeftSide() {
      this.showLeftSide = !this.showLeftSide;
    },
    getTotalTime(time, speed) {
      const days = ((time / speed) / 1440) + 0.5;
      const hours = ((time / speed) / 60 + 12) % 24;
      const minutes = (time / speed) % 60;
      return {
        days: Math.trunc(days),
        hours: Math.trunc(hours),
        minutes: Math.trunc(minutes),
      };
    },
  },
};
</script>

<style scoped>
  .parts-table tr * { vertical-align: middle; }
</style>
