<template>
    <section>
      <v-container v-if="selectedRoute" fluid>
        <v-row no-gutters denswe>
          <v-col cols="12">
            <div class="subtitle-2">Current route</div>
            <v-breadcrumbs :items="selectedRoute.sectors">
              <template v-slot:divider>
                <v-icon>mdi-arrow-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item>
                  {{ item.name_en }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row no-gutters dense>
              <v-container>
                <v-row>
                  <div class="subtitle-2">Breakpoints for this route</div>
                </v-row>
                <v-row>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Distance</th>
                        <th>Time</th>
                        <th>Surveillance</th>
                        <th>Retrieval</th>
                        <th>Favor</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>{{ selectedRoute.distance }}</td>
                        <td>{{ selectedRoute.time }}</td>
                        <td>
                  <span v-if="breakpoints.surveillance">
                    {{ breakpoints.surveillance.mid }} | {{ breakpoints.surveillance.high }}
                  </span>
                        </td>
                        <td>
                  <span v-if="breakpoints.retrieval">
                    {{ breakpoints.retrieval.norm }} | {{ breakpoints.retrieval.optim }}
                  </span>
                        </td>
                        <td>{{ breakpoints.favor }}</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
              </v-container>
            </v-row>
            <v-row no-gutters dense>
              <v-container fluid>
                <v-row>
                  <div class="subtitle-2">Breakpoints that will be applied</div>
                </v-row>
                <v-row>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Surveillance</th>
                        <th>Retrieval</th>
                        <th>Favor</th>
                        <th>Range</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                    <span v-if="breakpointsToApply.surveillance">
                      {{ breakpointsToApply.surveillance }}
                    </span>
                        </td>
                        <td>
                    <span v-if="breakpointsToApply.retrieval">
                      {{ breakpointsToApply.retrieval }}
                    </span>
                        </td>
                        <td>
                    <span v-if="breakpointsToApply.favor">
                      {{ breakpointsToApply.favor }}
                    </span>
                        </td>
                        <td>
                    <span v-if="breakpointsToApply.range">
                      {{ breakpointsToApply.range }}
                    </span>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
              </v-container>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row no-gutters dense>
              <div class="subtitle-2 mb-2">
                Apply breakpoints <i>(will ignore min/exact value)</i>
              </div>
            </v-row>
            <v-row class="text-body-2 no-gutters" dense>
              <v-col cols="3" v-if="breakpoints.surveillance">
                <div>Surveillance</div>
                <v-radio-group class="my-0" v-model="surveillance" dense hide-details>
                  <v-radio
                    class="my-0"
                    label="None"
                    :value="null"
                  ></v-radio>
                  <v-radio
                    v-if="breakpoints.surveillance.mid"
                    class="my-0"
                    label="Mid"
                    :value="breakpoints.surveillance.mid"
                  ></v-radio>
                  <v-radio
                    v-if="breakpoints.surveillance.high"
                    class="my-0"
                    label="High"
                    :value="breakpoints.surveillance.high"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3" v-if="breakpoints.retrieval">
                <div>Retrieval</div>
                <v-radio-group class="my-0" v-model="retrieval" dense hide-details>
                  <v-radio
                    class="my-0"
                    label="None"
                    :value="null"
                  ></v-radio>
                  <v-radio
                    v-if="breakpoints.retrieval.norm"
                    class="my-0"
                    label="Norm"
                    :value="breakpoints.retrieval.norm"
                  ></v-radio>
                  <v-radio
                    v-if="breakpoints.retrieval.optim"
                    class="my-0"
                    label="Optim"
                    :value="breakpoints.retrieval.optim"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3" v-if="breakpoints.favor">
                <div>Favor</div>
                <v-radio-group class="my-0" v-model="favor" dense hide-details>
                  <v-radio
                    class="my-0"
                    label="No"
                    :value="null"
                  ></v-radio>
                  <v-radio
                    v-if="breakpoints.favor"
                    class="my-0"
                    label="Yes"
                    :value="breakpoints.favor"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="3">
                <div>Range</div>
                <v-radio-group class="my-0" v-model="range" dense hide-details>
                  <v-radio
                    class="my-0"
                    label="No"
                    :value="null"
                  ></v-radio>
                  <v-radio
                    class="my-0"
                    label="Yes"
                    :value="selectedRoute.distance"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <div class="subtitle-2">No route selected.</div>
      </v-container>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AirshipCalculatorCurrentRoute',
  data() {
    return {
      surveillance: 0,
      retrieval: 0,
      favor: 0,
      range: 0,
    };
  },
  computed: {
    ...mapGetters('airshipsCalculator', [
      'routes',
      'selectedRoute',
      'breakpoints',
      'breakpointsToApply',
    ]),
  },
  watch: {
    selectedRoute() {
      this.surveillance = 0;
      this.retrieval = 0;
      this.favor = 0;
      this.range = this.selectedRoute.distance;
    },
    surveillance(newValue) {
      const value = newValue === 0 ? null : newValue;
      const toApply = { ...this.breakpointsToApply, surveillance: value };
      this.$store.commit('airshipsCalculator/SET_BREAKPOINTS_TO_APPLY', toApply);
    },
    retrieval(newValue) {
      const value = newValue === 0 ? null : newValue;
      const toApply = { ...this.breakpointsToApply, retrieval: value };
      this.$store.commit('airshipsCalculator/SET_BREAKPOINTS_TO_APPLY', toApply);
    },
    favor(newValue) {
      const value = newValue === 0 ? null : newValue;
      const toApply = { ...this.breakpointsToApply, favor: value };
      this.$store.commit('airshipsCalculator/SET_BREAKPOINTS_TO_APPLY', toApply);
    },
    range(newValue) {
      const toApply = { ...this.breakpointsToApply, range: newValue };
      this.$store.commit('airshipsCalculator/SET_BREAKPOINTS_TO_APPLY', toApply);
    },
  },
};
</script>

<style scoped>
  .table tr * { text-align: center; }
</style>
