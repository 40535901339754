<template>
  <section>
    <AirshipCalculatorStatFields label="Rank"
                                   v-model="computedRank"></AirshipCalculatorStatFields>
    <AirshipCalculatorStatFields label="Surveillance"
                                   v-model="computedSurveillance"></AirshipCalculatorStatFields>
    <AirshipCalculatorStatFields label="Retrieval"
                                   v-model="computedRetrieval"></AirshipCalculatorStatFields>
    <AirshipCalculatorStatFields label="Speed"
                                   v-model="computedSpeed"></AirshipCalculatorStatFields>
    <AirshipCalculatorStatFields label="Range"
                                   v-model="computedRange"></AirshipCalculatorStatFields>
    <AirshipCalculatorStatFields label="Favor"
                                   v-model="computedFavor"></AirshipCalculatorStatFields>
  </section>
</template>

<script>
import AirshipCalculatorStatFields from '@/modules/airships/calculator/AirshipCalculatorStatFields.vue';

export default {
  name: 'AirshipCalculatorStatsFilters',
  components: { AirshipCalculatorStatFields },
  computed: {
    computedRank: {
      get() {
        return this.$store.state.airshipsCalculator.stats.rank;
      },
      set(newValue) {
        this.$store.commit('airshipsCalculator/SET_RANK_STAT', newValue);
      },
    },
    computedSurveillance: {
      get() {
        return this.$store.state.airshipsCalculator.stats.surveillance;
      },
      set(newValue) {
        this.$store.commit('airshipsCalculator/SET_SURVEILLANCE_STAT', newValue);
      },
    },
    computedRetrieval: {
      get() {
        return this.$store.state.airshipsCalculator.stats.retrieval;
      },
      set(newValue) {
        this.$store.commit('airshipsCalculator/SET_RETRIEVAL_STAT', newValue);
      },
    },
    computedSpeed: {
      get() {
        return this.$store.state.airshipsCalculator.stats.speed;
      },
      set(newValue) {
        this.$store.commit('airshipsCalculator/SET_SPEED_STAT', newValue);
      },
    },
    computedRange: {
      get() {
        return this.$store.state.airshipsCalculator.stats.range;
      },
      set(newValue) {
        this.$store.commit('airshipsCalculator/SET_RANGE_STAT', newValue);
      },
    },
    computedFavor: {
      get() {
        return this.$store.state.airshipsCalculator.stats.favor;
      },
      set(newValue) {
        this.$store.commit('airshipsCalculator/SET_FAVOR_STAT', newValue);
      },
    },
  },
};
</script>

<style scoped>
</style>
