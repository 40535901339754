<template>
    <v-simple-checkbox dense v-model="isChecked" :value="isChecked" @click="toggle">
      <template v-slot:label>{{ label }}</template>
    </v-simple-checkbox>
</template>

<script>
import Ripple from 'vuetify/lib/directives';

export default {
  name: 'AirshipCalculatorPartsSelectorCheckRow',
  props: ['label', 'columns', 'rows'],
  directives: {
    Ripple,
  },
  data() {
    return {
      isChecked: false,
    };
  },
  mounted() {
    this.calculateValue();
  },
  watch: {
    rows() {
      this.calculateValue();
    },
    columns() {
      this.calculateValue();
    },
  },
  methods: {
    calculateValue() {
      const values = this.rows || this.columns || null;
      this.isChecked = values?.length > 0 ? values.reduce((a, b) => a && b) : false;
    },
    toggle() {
      if (this.columns) {
        this.$emit('change', this.isChecked ? this.columns.map(() => true)
          : this.columns.map(() => false));
      } else if (this.rows) {
        this.$emit('change', this.isChecked);
      }
    },
  },
};
</script>

<style scoped>

</style>
