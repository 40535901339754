<template>
    <v-container fluid>
      <v-card>
          <AirshipMap></AirshipMap>
      </v-card>
    </v-container>
</template>

<script>
import AirshipMap from '../modules/airships/maps/AirshipMap.vue';

export default {
  name: 'AirshipMaps',
  components: { AirshipMap },
};
</script>

<style scoped>

</style>
