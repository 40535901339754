<template>
  <div>
    <v-simple-table class="parts-table" dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="width: 116px;">Name</th>
          <th class="text-center" style="padding-left: 10px; padding-right: 10px;">Hull</th>
          <th class="text-center" style="padding-left: 10px; padding-right: 10px;">Rigging</th>
          <th class="text-center" style="padding-left: 10px; padding-right: 10px;">Forecastle</th>
          <th class="text-center" style="padding-left: 10px; padding-right: 10px;">Aftcastle</th>
          <th class="text-center" style="padding-left: 10px; padding-right: 10px;">
            <AirshipCalculatorPartsSelectorCheckRow
              :rows="isAllChecked"
              @change="toggleAllParts">
            </AirshipCalculatorPartsSelectorCheckRow>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in selection" :key="item.name">
          <td>{{ item.name }}</td>
          <td class="text-center">
            <v-simple-checkbox dense v-model="selection[index].hull.checked"
                        :input-value="item.hull.shortname"></v-simple-checkbox>
          </td>
          <td class="text-center">
            <v-simple-checkbox dense v-model="selection[index].stern.checked"
                        :input-value="item.stern.shortname"></v-simple-checkbox>
          </td>
          <td class="text-center">
            <v-simple-checkbox dense v-model="selection[index].bow.checked"
                        :input-value="item.bow.shortname"></v-simple-checkbox>
          </td>
          <td class="text-center">
            <v-simple-checkbox dense v-model="selection[index].bridge.checked"
                        :input-value="item.bridge.shortname"></v-simple-checkbox>
          </td>
          <td class="text-center">
            <AirshipCalculatorPartsSelectorCheckRow
              :columns="selection[index] | toBooleanArray"
              @change="toggleAllPartsRow(index, $event)">
            </AirshipCalculatorPartsSelectorCheckRow>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import Ripple from 'vuetify/lib/directives';
import AirshipCalculatorPartsSelectorCheckRow from './AirshipCalculatorPartsSelectorCheckRow.vue';

export default {
  name: 'AirshipCalculatorPartsSelector',
  components: {
    AirshipCalculatorPartsSelectorCheckRow,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      partNames: [
        {
          name: 'Bronco',
          shortname: 'B',
        },
        {
          name: 'Invincible',
          shortname: 'i',
        },
        {
          name: 'Enterprise',
          shortname: 'E',
        },
        {
          name: 'Invincible II',
          shortname: 'i2',
        },
        {
          name: 'Odyssey',
          shortname: 'O',
        },
        {
          name: 'Tatanora',
          shortname: 'T',
        },
        {
          name: 'Viltgance',
          shortname: 'V',
        },
      ],
      selection: [],
    };
  },
  computed: {
    isAllChecked() {
      return this.selection.map((row) => this.toBooleanArray(row)
        .reduce((a, b) => a && b));
    },
  },
  watch: {
    selection() {
      this.$emit('input', this.selection);
    },
  },
  filters: {
    toBooleanArray(row) {
      return Object.keys(row)
        .filter((c) => row[c].checked !== undefined)
        .map((column) => row[column].checked);
    },
  },
  async mounted() {
    await this.initializeSelection();
  },
  methods: {
    toBooleanArray(row) {
      return Object.keys(row)
        .filter((c) => row[c].checked !== undefined)
        .map((column) => row[column].checked);
    },
    async initializeSelection() {
      const parts = (await this.$http.get('/airship/parts'))?.data?.results;
      this.partNames.forEach((part) => {
        this.selection.push({
          ...part,
          hull: {
            ...part,
            checked: true,
            id: parts.filter((p) => p.shortname_en === part.shortname && p.slot === 0)[0].id,
          },
          stern: {
            ...part,
            checked: true,
            id: parts.filter((p) => p.shortname_en === part.shortname && p.slot === 1)[0].id,
          },
          bow: {
            ...part,
            checked: true,
            id: parts.filter((p) => p.shortname_en === part.shortname && p.slot === 2)[0].id,
          },
          bridge: {
            ...part,
            checked: true,
            id: parts.filter((p) => p.shortname_en === part.shortname && p.slot === 3)[0].id,
          },
        });
      });
    },
    toggleAllParts(isChecked) {
      const newSelection = this.selection.slice();
      newSelection.map((row) => {
        const newRow = { ...row };
        Object.keys(newRow).filter((key) => newRow[key].checked !== undefined).forEach((key) => {
          newRow[key].checked = isChecked;
        });
        return newRow;
      });
      this.selection = newSelection;
      console.log(this.selection);
    },
    toggleAllPartsRow(i, value) {
      const newSelection = this.selection.slice();
      const newRow = newSelection[i];
      let counter = 0;
      Object.keys(newRow).filter((key) => newRow[key].checked !== undefined).forEach((key) => {
        newRow[key].checked = value[counter];
        counter += 1;
      });
      newSelection[i] = newRow;
      this.$set(this, 'selection', newSelection);
    },
  },
};
</script>

<style scoped>
.parts-table tr * { vertical-align: middle; }
div.v-messages { min-height: 0; }
</style>
